<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        md="4"
        offset="4"
      >
        <!-- <analytics-congratulation /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" v-if="iCan(`employees`, `write`)">
        <b-link :to="{
            name: `create-employee`,
            params: {software: 'hr'}
          }">
          <statistic-card-vertical
            color="primary"
            icon="UserIcon"
            :statistic="i18nT(`Employee`)"
            :statistic-title="i18nT(`Create new employee`)"
          />
        </b-link>
      </b-col>
      <b-col md="3" v-if="iCan(`tasks`, `write`)">
        <b-link :to="{
            name: `create-task`,
            params: {software: 'hr'}
          }">
          <statistic-card-vertical
            color="primary"
            icon="CheckCircleIcon"
            :statistic="i18nT(`Task`)"
            :statistic-title="i18nT(`Create new task`)"
          />
        </b-link>
      </b-col>
      <b-col md="3" v-if="iCan(`documents`, `write`)">
        <b-link :to="{
            name: `create-document`,
            params: {software: 'hr'}
          }">
          <statistic-card-vertical
            color="primary"
            icon="FileTextIcon"
            :statistic="i18nT(`Document`)"
            :statistic-title="i18nT(`Create new document`)"
          />
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link :to="{
            name: `inbox-new`,
            query: {
                      newMsg: 1
                  }
          }">
          <statistic-card-vertical
            color="primary"
            icon="MailIcon"
            :statistic="i18nT(`Message`)"
            :statistic-title="i18nT(`Send new message`)"
          />
        </b-link>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4" v-if="iCan(`leave_absence`, `read`)">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25">
              {{leaves.count}} {{i18nT(`Time off requests`)}}
            </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <b-media
              no-body
              v-for="leave in leaves.items"
              :key="leave.Id"
              class="media pb-50 pt-50 border-bottom"
            >
              <b-link
                class="d-flex"
                :to="{ name: 'hr-leave-absence', query: {leaveId: leave.Id}}"
              >
                <b-media-aside class="mr-50">
                  <b-avatar
                    :src="leave.EmployeeUser.ImageUrl"
                    :text="(leave.EmployeeUser.FirstName ? leave.EmployeeUser.FirstName.charAt(0) : '') +
                            (leave.EmployeeUser.LastName ? leave.EmployeeUser.LastName.charAt(0) : '')"

                    badge-variant="primary"
                    class="badge-light-primary"

                >
                </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0">
                    {{ leave.EmployeeUser.Label }}
                    <span class="text-secondary">
                      ({{ absenceType(leave.AbsenceType) }})
                    </span> 
                  </h6>
                  <p class="mb-0">
                    {{ leave.StartDate | date }} - {{ leave.EndDate | date }}
                  </p>
                </b-media-body>
              </b-link>
            </b-media>
            <b-media
              style="text-align: center;"
              v-if="leaves.count === 0"
            >
              <span
                class="b-avatar badge-light-secondary rounded mb-2 mt-4"
                style="width: 70px; height: 70px;"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="36"
                />
              </span>
              <h5>{{i18nT(`Nothing new`)}}</h5>
              <p class="text-muted">{{i18nT(`There are no new requests.`)}}</p>
            </b-media>
            <!--/ timeline -->
          </b-card-body>
          <b-link
            :to="{ name: 'hr-leave-absence'}"
            style="padding: 8px;"
          >
            <b-button
              variant="outline-secondary"
              style="width: 100%;"
            >
              {{i18nT(`See all`)}}
            </b-button>
          </b-link>
        </b-card>
      </b-col>
      <b-col lg="4" v-if="iCan(`timesheets`, `read`)">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25">
              {{timesheets.count}} {{i18nT(`Timesheets`)}}
            </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <b-media
              no-body
              v-for="timesheet in timesheets.items"
              :key="timesheet.Id"
              class="media pb-50 pt-50 border-bottom"
            >
              <b-link
                class="d-flex"
                :to="{ name: 'hr-timesheets', query: { timesheetId: timesheet.Id }}"
              >
                <b-media-aside class="mr-50">
                  <b-avatar
                    :src="timesheet.EmployeeUser.ImageUrl"
                    :text="(timesheet.EmployeeUser.FirstName ? timesheet.EmployeeUser.FirstName.charAt(0) : '') +
                            (timesheet.EmployeeUser.LastName ? timesheet.EmployeeUser.LastName.charAt(0) : '')"
                    badge-variant="primary"
                    class="badge-light-primary"
                  />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0">
                    {{ timesheet.EmployeeUser.Label }}
                    <span class="text-secondary"> - {{ timesheet.TotalHours }}{{ i18nT(`h`) }} {{ timesheet.TotalMinutes }}{{ i18nT(`min`) }}</span>
                  </h6>
                  <p class="mb-0">
                    {{ timesheet.Day | date}}
                  </p>
                </b-media-body>
              </b-link>
            </b-media>
            <b-media
              style="text-align: center;"
              v-if="timesheets.count === 0"
            >
              <span
                class="b-avatar badge-light-secondary rounded mb-2 mt-4"
                style="width: 70px; height: 70px;"
              >
                <feather-icon
                  icon="ClockIcon"
                  size="36"
                />
              </span>
              <h5>{{i18nT(`Nothing new`)}}</h5>
              <p class="text-muted">{{i18nT(`There are no new entries.`)}}</p>
            </b-media>
            <!--/ timeline -->
          </b-card-body>
          <b-link
            :to="{name: 'hr-timesheets'}"
            style="padding: 8px;"
          >
            <b-button
              variant="outline-secondary"
              style="width: 100%;"
            >
              {{i18nT(`See all`)}}
            </b-button>
          </b-link>
        </b-card>
      </b-col>
      <b-col lg="4">
        <analytics-messages
          :data="messages"
          manageLink="/hr/inbox"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BButton,
  BLink
} from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './analytics/AnalyticsCongratulation.vue'
import AnalyticsMessages from './analytics/AnalyticsMessages.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import _ from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    BCard,
    StatisticCardVertical,
    AnalyticsMessages,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BButton,
    BLink
  },
  data() {
    return {
      data: {},
      onboardings: [],
      offboardings: [],
      absenceTypes: [{
        value: -1,
        text: ''
      }],
      messages: {
        messages: [],
        count: 0
      },
      leaves: {
        items: [],
        count: 0
      },
      timesheets: {
        items: [],
        count: 0
      }
    }
  },
  created() {
    this.$http.get(`main/hrDashboard`).then(({ data }) => {
      console.log(data)
      this.messages = {
        messages: data.data.messages,
        count: data.data.messages_count
      }
      this.leaves = {
        items: data.data.leaves,
        count: data.data.leaves_count
      }
      this.timesheets = {
        items: data.data.timesheets,
        count: data.data.timesheets_count
      }
    })

    this.$http.get(`hr/absenceTypes`).then(({data}) => {
      let types = data.data.map(item => ({
          value: item.Id,
          text: item.Label
      }))
      this.absenceTypes = types;
  })
  },
  methods: {
    kFormatter,
    absenceType(id) {
      try{
        return this.absenceTypes.find(type => type.value === id).text;
      } catch(ex) {
        return '';
      }

    }
  },
}
</script>
