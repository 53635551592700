var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-analytics"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"4","offset":"4"}})],1),_c('b-row',[(_vm.iCan("employees", "write"))?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{
          name: "create-employee",
          params: {software: 'hr'}
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"UserIcon","statistic":_vm.i18nT("Employee"),"statistic-title":_vm.i18nT("Create new employee")}})],1)],1):_vm._e(),(_vm.iCan("tasks", "write"))?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{
          name: "create-task",
          params: {software: 'hr'}
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"CheckCircleIcon","statistic":_vm.i18nT("Task"),"statistic-title":_vm.i18nT("Create new task")}})],1)],1):_vm._e(),(_vm.iCan("documents", "write"))?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{
          name: "create-document",
          params: {software: 'hr'}
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"FileTextIcon","statistic":_vm.i18nT("Document"),"statistic-title":_vm.i18nT("Create new document")}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{attrs:{"to":{
          name: "inbox-new",
          query: {
                    newMsg: 1
                }
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"MailIcon","statistic":_vm.i18nT("Message"),"statistic-title":_vm.i18nT("Send new message")}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[(_vm.iCan("leave_absence", "read"))?_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.leaves.count)+" "+_vm._s(_vm.i18nT("Time off requests"))+" ")])],1),_c('b-card-body',[_vm._l((_vm.leaves.items),function(leave){return _c('b-media',{key:leave.Id,staticClass:"media pb-50 pt-50 border-bottom",attrs:{"no-body":""}},[_c('b-link',{staticClass:"d-flex",attrs:{"to":{ name: 'hr-leave-absence', query: {leaveId: leave.Id}}}},[_c('b-media-aside',{staticClass:"mr-50"},[_c('b-avatar',{staticClass:"badge-light-primary",attrs:{"src":leave.EmployeeUser.ImageUrl,"text":(leave.EmployeeUser.FirstName ? leave.EmployeeUser.FirstName.charAt(0) : '') +
                          (leave.EmployeeUser.LastName ? leave.EmployeeUser.LastName.charAt(0) : ''),"badge-variant":"primary"}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(leave.EmployeeUser.Label)+" "),_c('span',{staticClass:"text-secondary"},[_vm._v(" ("+_vm._s(_vm.absenceType(leave.AbsenceType))+") ")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm._f("date")(leave.StartDate))+" - "+_vm._s(_vm._f("date")(leave.EndDate))+" ")])])],1)],1)}),(_vm.leaves.count === 0)?_c('b-media',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"b-avatar badge-light-secondary rounded mb-2 mt-4",staticStyle:{"width":"70px","height":"70px"}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"36"}})],1),_c('h5',[_vm._v(_vm._s(_vm.i18nT("Nothing new")))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.i18nT("There are no new requests.")))])]):_vm._e()],2),_c('b-link',{staticStyle:{"padding":"8px"},attrs:{"to":{ name: 'hr-leave-absence'}}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.i18nT("See all"))+" ")])],1)],1)],1):_vm._e(),(_vm.iCan("timesheets", "read"))?_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.timesheets.count)+" "+_vm._s(_vm.i18nT("Timesheets"))+" ")])],1),_c('b-card-body',[_vm._l((_vm.timesheets.items),function(timesheet){return _c('b-media',{key:timesheet.Id,staticClass:"media pb-50 pt-50 border-bottom",attrs:{"no-body":""}},[_c('b-link',{staticClass:"d-flex",attrs:{"to":{ name: 'hr-timesheets', query: { timesheetId: timesheet.Id }}}},[_c('b-media-aside',{staticClass:"mr-50"},[_c('b-avatar',{staticClass:"badge-light-primary",attrs:{"src":timesheet.EmployeeUser.ImageUrl,"text":(timesheet.EmployeeUser.FirstName ? timesheet.EmployeeUser.FirstName.charAt(0) : '') +
                          (timesheet.EmployeeUser.LastName ? timesheet.EmployeeUser.LastName.charAt(0) : ''),"badge-variant":"primary"}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(timesheet.EmployeeUser.Label)+" "),_c('span',{staticClass:"text-secondary"},[_vm._v(" - "+_vm._s(timesheet.TotalHours)+_vm._s(_vm.i18nT("h"))+" "+_vm._s(timesheet.TotalMinutes)+_vm._s(_vm.i18nT("min")))])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm._f("date")(timesheet.Day))+" ")])])],1)],1)}),(_vm.timesheets.count === 0)?_c('b-media',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"b-avatar badge-light-secondary rounded mb-2 mt-4",staticStyle:{"width":"70px","height":"70px"}},[_c('feather-icon',{attrs:{"icon":"ClockIcon","size":"36"}})],1),_c('h5',[_vm._v(_vm._s(_vm.i18nT("Nothing new")))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.i18nT("There are no new entries.")))])]):_vm._e()],2),_c('b-link',{staticStyle:{"padding":"8px"},attrs:{"to":{name: 'hr-timesheets'}}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.i18nT("See all"))+" ")])],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"4"}},[_c('analytics-messages',{attrs:{"data":_vm.messages,"manageLink":"/hr/inbox"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }